import React from 'react'
import styled from 'styled-components'

const InputParent = styled.div`
  input {
    width: 100%;
    text-align: center;
    outline: none;
    border: 1px solid rgba(0,0,0,0);
    border-bottom: 1px solid #fff;
    padding: 0.5rem;
    font-size: 1.2rem;
    color: #fff;
    font-family: var(--font);
    margin-bottom: 1.5rem;
    transition: 0.3s ease-out border, 0.3s ease-out border-radius;

    &:focus {
      border: 1px solid #fff;
      border-radius: 0.5rem;
    }

    &::placeholder {
      color: #fff;
    }
  }

`

const Input = ({
  placeholder,
  type,
  name,
  onChangeEvt,
  value
}) =>
  <InputParent>
    <input
      required
      placeholder={placeholder}
      type={type}
      name={name}
      onChange={onChangeEvt}
    />
  </InputParent>

export default Input
