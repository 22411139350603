import React from 'react'
import styled from 'styled-components'

import { H1 } from '../Typography'

const SubmittedText = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  transform: translate(-50%, -50%);
`


const Section = () =>
  <SubmittedText>
    <H1 color='#fff'>Thank you!<br /> Expect an email soon.</H1>
  </SubmittedText>

export default Section