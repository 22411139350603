import React, { PureComponent } from "react";
import styled from "styled-components";
import queryString from "query-string";

import Input from "./Input";
import { StyledButton } from "../Button";
import SubmittedText from "./SubmittedText";

import { P, Button as ButtonText } from '../Typography'

import { ENV, MAILING_LIST_FORM_URL, RECAPTCHA_SITE_KEY, LS_THANKYOU_PAGE_TYPE } from "../../constants";

const Section = styled.section`
  padding: 3rem;
  max-width: 25rem;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  z-index: 30;

  button {
    outline: none;
    transition: 0.2s ease-out transform;

    &:active {
      transform: scale(0.95, 0.95);
    }
  }

  form {
    z-index: 30;
  }

  ${({ submitted }) => {
    return (
      submitted &&
      `
      form {
        opacity: 0;
        pointer-events: none;
      }
    `
    );
  }}

  @media (max-width: 750px) {
    width: 100%;
    max-width: 40rem;
  }

  .joinDisclaimer {
    padding-top: 0.8rem;
    font-size: 0.8rem;
    color: var(--lilac);
  }
`;

export default class Form extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      text: null,
      submitted: false,
      completed: false,
      utmCampaign: "",
      utmSource: "",
      utmMedium: "",
      utmContent: "",
      utmTerm: "",
      email: "",
      ip: "",
      ipAsNum: 0
    }
  }

  fetchIp() {
    if (!window) {
      return
    }

    window
      .fetch('https://play.skyweaver.net/cdn-cgi/trace')
      .then(res => {
        return res.text()
      })
      .then(data => {
        let ipAsString = ''
        let ipAsNum = 0
        let regx = /\b\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}\b/
        let ip = data.match(regx)
        if (ip) {
          // IPv4
          ipAsString = ip.toString()
          ipAsNum =
            ipAsString.split('.').reduce(function (ipInt, octet) {
              return (ipInt << 8) + parseInt(octet, 10)
            }, 0) >>> 0
        } else {
          // IPv6
          const v = data.match(/\bip=(.*)\b/)
          if (v) {
            ipAsString = v[1].toString()
          } else {
            console.error(`Error parsing IP ${data}`)
          }
        }
        if (ipAsString) {
          this.setState({ ip: ipAsString })
          this.setState({ ipAsNum })
        }
      })
      .catch(error => {
        console.error(`Error fetching IP endpoint ${error}`)
      })
  }

  componentDidMount() {
    this.fetchIp()

    if (window.location.search.includes("?submitted=true")) {
      this.setState({ submitted: true }, () => {
        setTimeout(() => {
          window.scrollTo(0, 8888);
        }, 300);
      });
    }

    const parsed = queryString.parse(window.location.search);

    if (parsed.utm_campaign) {
      this.setState({ utmCampaign: parsed.utm_campaign });
    }

    if (parsed.utm_source) {
      this.setState({ utmSource: parsed.utm_source });
    }

    if (parsed.utm_medium) {
      this.setState({ utmMedium: parsed.utm_medium });
    }

    if (parsed.utm_content) {
      this.setState({ utmContent: parsed.utm_content });
    }

    if (parsed.utm_term) {
      this.setState({ utmTerm: parsed.utm_term });
    }
  }

  onSubmit(email, utm_campaign, utm_content, utm_source, utm_medium, utm_term, ip, ip_as_num, onSuccess, onError) {
    if (window) {
      window.grecaptcha.ready(function () {
        window.grecaptcha.execute(RECAPTCHA_SITE_KEY[ENV], { action: 'submit' }).then(function (token) {
          fetch(`${MAILING_LIST_FORM_URL[ENV]}`, {
            method: "POST",
            redirect: "follow",
            mode: "no-cors",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              email,
              token,
              utm_content,
              utm_campaign,
              utm_source,
              utm_medium,
              utm_term,
              referrer: document.referrer,
              ip,
              ip_as_num
            }),
          })
            .then(onSuccess)
            .catch(onError)
        })
      })
    }
  }

  render() {
    const { submitted, completed, utmCampaign, utmContent, utmMedium, utmSource, utmTerm, email, ip, ipAsNum } = this.state;
    return (
      <Section submitted={completed}>
        <form
          method={`POST`}
          target="_blank"
          name="mc-embedded-subscribe-form"
          onSubmit={(e) => {
            e.preventDefault()
            this.setState({ submitted: true })
            localStorage.setItem(LS_THANKYOU_PAGE_TYPE, 'signup')
            this.onSubmit(email, utmCampaign, utmContent, utmSource, utmMedium, utmTerm, ip, ipAsNum,
              (d) => {
                this.setState({ completed: true })
                window.location.href = "/thanks"
              },
              (e) => {
                console.error(`Error saving early access waitlist email ${e}`)
              })
          }}
        >
          <input type="text" name="email_address_check" value="" readOnly className="input--hidden" />
          <input type="hidden" name="locale" value="en" readOnly />
          <Input id="defaultEMAIL" name="EMAIL" type="email" placeholder="Enter your email"
            onChangeEvt={(e) => { this.setState({ email: e.target.value }) }} />
          <button type="submit" style={{ width: "100% ", pointerEvents: submitted ? 'none' : '' }}>
            <StyledButton as="span" color={"var(--dark)"} width="100%" style={{ style: { cursor: submitted ? 'progress' : '' } }}>
              <ButtonText color={"var(--dark)"}>
                Join Now
              </ButtonText>
            </StyledButton>
            {/* <ButtonLink as="div" color={"var(--dark)"} width="100%" style={{ style: { cursor: submitted ? 'progress' : '' } }}>
              Join The Waitlist
            </ButtonLink> */}
          </button>
        </form>
        <P className="joinDisclaimer">By clicking Join, you agree to receive communications from Skyweaver. You can unsubscribe anytime.</P>
        {completed && <SubmittedText />}
      </Section >
    );
  }
}
